import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

import { Loader, ProgressBar } from 'components/atomic/atoms';
import { Footer, PageNotFound } from 'components/atomic/molecules';
import {
  HeaderMobileOrganism,
  HeaderWebOrganism,
  ToastContainerOrganism
} from 'components/atomic/organisms';
import LdJSONSchemaScriptTag from 'components/general/LdJSONSchemaScriptTag';
import {
  STATIC_MEDIA_STORE_PATH_STUB,
  mediaStoreBaseURL
} from 'config/common';
import { GetUiDeviceSurface } from 'lib/screenResolution';
import { PARTNER_PORTAL_URL } from 'services/connections.service';

const headerMap = {
  mobile: HeaderMobileOrganism,
  web: HeaderWebOrganism
};

const StandardLayout = ({
  children,
  loading,
  pageDataFound = true,
  pageHeadData: {
    canonicalPath,
    ldJson,
    metaDescription,
    metaTitle,
    ogDescription,
    ogImage,
    ogTitle
  } = {},
  pageNotFoundProps: {
    message = 'Oh Oh! Page Not Found',
    subMessage1 = 'The page you are looking for does not exist.',
    subMessage2 = 'Please check the URL and try again.'
  } = {},
  setShowToast,
  showCallIcon,
  showFooter = true,
  showHeader = true,
  showLoader = false,
  showToast = { show: false, status: false, message: '' },
  showWhatsapp = true
}) => {
  const router = useRouter();
  const surface = GetUiDeviceSurface();

  if (loading) {
    return <Loader />;
  }

  const direction = 'ltr';
  const Header = headerMap[surface];

  const ogImageSrc = ogImage
    ? `${mediaStoreBaseURL}/${ogImage}`
    : `${STATIC_MEDIA_STORE_PATH_STUB}/icons/hafla-icon.png`;
  const canonicalPathURL = canonicalPath
    ? `${PARTNER_PORTAL_URL}/${canonicalPath}`
    : null;

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        canonical={canonicalPathURL}
        openGraph={{
          title: ogTitle,
          description: ogDescription,
          images: [{ url: ogImageSrc }],
          url: `${PARTNER_PORTAL_URL}${router.asPath}`
        }}
      />
      {ldJson && <LdJSONSchemaScriptTag ldJson={ldJson} />}
      <ToastContainerOrganism {...{ showToast, setShowToast }} />
      {pageDataFound ? (
        <div
          className='flex flex-col justify-between h-screen'
          id='standard-layout-wrapper'
        >
          <div dir={direction}>
            {showLoader && <ProgressBar />}
            {showHeader && (
              <Header
                {...{
                  showWhatsapp,
                  showCallIcon
                }}
              />
            )}
            <main
              className='min-h-80 md:min-h-96'
              id='main-content'
            >
              {children}
            </main>
            {showFooter && <Footer />}
          </div>
        </div>
      ) : (
        <PageNotFound
          {...{
            message,
            subMessage1,
            subMessage2
          }}
        />
      )}
    </>
  );
};

export default StandardLayout;
