import { useRouter, withRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  ContactUs,
  LoginInterface,
  WelcomeMessage
} from 'components/atomic/molecules';
import { OtpInputInterfaceOrganism } from 'components/atomic/organisms';
import { useUIContext } from 'components/context/ManagedUIContext';
import StandardLayout from 'components/layout/StandardLayout';
import { DEFAULT_TOAST_CONFIG } from 'config/common';
import phoneCountryCodes from 'config/phoneCountryCodes';
import isMobileNumberValid from 'lib/validations/mobile-number';
import YupValidator from 'lib/yup-validator';
import { isAuthenticated } from 'services/identity.service';
import {
  LOGIN_PAGE_JSX_MAP,
  onClickEditButton,
  partnerUserAuthentication,
  verifyOTP
} from 'services/login.service';

const Login = ({ router: { query } }) => {
  const router = useRouter();

  const { login, redirectAuthenticatedUser } = useUIContext();

  const [activeJSX, setActiveJSX] = useState(LOGIN_PAGE_JSX_MAP.LOGIN_JSX);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(null);
  const [showToast, setShowToast] = useState(DEFAULT_TOAST_CONFIG);
  const [loading, setLoading] = useState(false);

  const isAuthenticatedUser = isAuthenticated();

  const yupResolver = YupValidator(
    yup.object().shape({
      phoneNumber: yup
        .string()
        .test('is-valid-number', 'Invalid phone number', (value, { parent }) =>
          isMobileNumberValid(`${parent.codeId.code}${value}`)
        )
        .required('Phone number is required')
    })
  );

  const {
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      codeId: phoneCountryCodes[0],
      phoneNumber: ''
    }
  });

  useEffect(() => {
    isAuthenticatedUser && redirectAuthenticatedUser({ router });
  }, [router, isAuthenticatedUser]);

  const loginPageJSXMap = {
    [LOGIN_PAGE_JSX_MAP.CONTACT_US_MODAL_JSX]: ContactUs,
    [LOGIN_PAGE_JSX_MAP.LOGIN_JSX]: LoginInterface,
    [LOGIN_PAGE_JSX_MAP.OTP_INPUT_INTERFACE_JSX]: OtpInputInterfaceOrganism
  };

  const LoginPageJSX = loginPageJSXMap[activeJSX];

  return (
    <StandardLayout
      {...{
        loading,
        pageHeadData: {
          ...{
            metaDescription: 'Login',
            metaTitle: 'Login'
          }
        },
        showHeader: false,
        showFooter: false,
        showToast,
        setShowToast
      }}
    >
      <div className='bg-user-login min-h-screen flex justify-center relative'>
        <WelcomeMessage
          {...{
            isLoginPage: true,
            haflaLogo: 'hafla.svg',
            openContactUsModal:
              activeJSX === LOGIN_PAGE_JSX_MAP.CONTACT_US_MODAL_JSX
          }}
        />
        <LoginPageJSX
          {...{
            clearErrors,
            errors,
            getValues,
            login,
            onClickEditButton,
            onClickResendOTP: partnerUserAuthentication,
            otp,
            otpError,
            partnerUserAuthentication: () =>
              handleSubmit(
                partnerUserAuthentication({
                  getValues,
                  setActiveJSX,
                  setOtp,
                  setOtpError
                })
              ),
            query,
            register,
            setActiveJSX,
            setLoading,
            setOtp,
            setOtpError,
            setShowToast,
            setValue,
            verifyOTP
          }}
        />
      </div>
    </StandardLayout>
  );
};

export default withRouter(Login);
